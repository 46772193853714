type inputType = 'name' | 'card' | 'cvv' | 'exp';

/**
 *
 * @param inputType type of input to validate
 * @param inputValue value of input to validate
 * @returns  {boolean}  true if input is valid else false
 */
export function useFormValidation(inputType: inputType, inputValue: any) {
    //if empty return false
    if (inputValue === '') return false;

    //validate card number
    if (inputType === 'card') {
        //remove spaces and _ from prime mask
        inputValue = inputValue.replace(/[\s_]/g, '');
        const cardRegex = /^[0-9]{16}$/;
        return cardRegex.test(inputValue);
    }

    //validate cvv
    if (inputType === 'cvv') {
        const cvvRegex = /^[0-9]{3}$/;
        return cvvRegex.test(inputValue);
    }

    //validate name
    if (inputType === 'name') {
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(inputValue);
    }

    //validate expiry date
    if (inputType === 'exp') {
        inputValue = inputValue.replace(/_/g, '');

        const expRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        return expRegex.test(inputValue);
    }
}
